/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { Card, Form } from "react-bootstrap";
import { getDateNDaysAgo } from "../../utils/helperFunctions";

export default function CallsByStatusGraph({ data }) {
  const [days, setDays] = useState(7);
  const [graphData, setGraphData] = useState(undefined);
  const [total, setTotal] = useState(0);

  const filterData = () => {
    let dataObj = [];
    const temp = data?.filter((d) => {
      return new Date(d.createdAt.seconds * 1000) >= getDateNDaysAgo(days);
    });
    setTotal(temp.length);
    const tempCalls = {
      "Order Confirmed": 0,
      Lead: 0,
      "No Order": 0,
      "Not Available": 0,
    };
    temp.forEach((d) => (tempCalls[d.status] += 1));
    for (let i = 0; i < Object.keys(tempCalls).length; i++) {
      dataObj.push({
        name: Object.keys(tempCalls)[i],
        Calls: Object.values(tempCalls)[i],
      });
    }
    setGraphData(dataObj);
  };

  useEffect(() => {
    filterData();
  }, [days]);

  return (
    <Card className="p-2">
      <div className="chart-title">
        <h2>Total Calls By Status ({total})</h2>
        <Form.Select
          value={days}
          onChange={(e) => setDays(e.target.value)}
          style={{ width: 200 }}
        >
          <option disabled value={-1}>
            Select Days
          </option>
          <option value={7}>Past 7 days</option>
          <option value={30}>Past 30 days</option>
          <option value={90}>Past 3 months</option>
          <option value={180}>Past 6 months</option>
          {/* <option value={-1}>So Far</option> */}
        </Form.Select>
      </div>
      {graphData && (
        <BarChart
          width={600}
          height={300}
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Calls" fill="#82ca9d" />
        </BarChart>
      )}
    </Card>
  );
}
