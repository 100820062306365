import { useEffect, useState, useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { db } from "../utils/firebase";
import PFIHeader from "../components/PFI/PFIHeader";
import BankDetails from "../components/PFI/BankDetails";
import ProductsTable from "../components/PFI/ProductsTable";
import Clients from "../components/PFI/Clients";
import PFIFooter from "../components/PFI/PFIFooter";
import { BiErrorCircle } from "react-icons/bi";
import "../styles/pfi.css";

export default function PFI() {
  const [pfiCall, setPfiCall] = useState(undefined);
  const [customer, setCustomer] = useState(undefined);
  const [agent, setAgent] = useState(undefined);
  const [attention, setAttention] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [paymentType, setPaymentType] = useState("Cash");
  const [isVatApplicable, setIsVatApplicable] = useState(true);
  const [showModal, setShowModal] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const pfiRef = useRef();
  const { id } = useParams();

  const fetchCustomer = (id) => {
    db.collection("customers")
      .doc(id)
      .onSnapshot((snapshot) => {
        setCustomer(snapshot.data());
      });
  };

  const fetchSR = (id) => {
    db.collection("users")
      .doc(id)
      .onSnapshot((snapshot) => {
        setAgent(snapshot.data());
      });
  };

  const fetchCallDetail = async () => {
    db.collection("calls")
      .doc(id)
      .onSnapshot((snapshot) => {
        setPfiCall(snapshot.data());
        fetchCustomer(snapshot.data().customer_id);
        fetchSR(snapshot.data().agent_id);
      });
  };

  useEffect(() => {
    fetchCallDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const printProforma = () => {
    document.title = `marango_pfi_${pfiCall?.id}`;
    var printContents = pfiRef.current.innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
    window.location.reload(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!attention) {
      setErrorMessage("Attention cannot is empty!");
      return;
    } else {
      setShowModal(false);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
        centered
        backdrop="static"
      >
        <Modal.Header style={{ borderBottom: "none", paddingBottom: 0 }}>
          <Modal.Title>Add Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            {/* Attention */}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 600 }}>Attention</Form.Label>
              <Form.Control
                type="text"
                placeholder="Attention"
                value={attention}
                onChange={(e) => setAttention(e.target.value)}
              />
            </Form.Group>

            {/* Terms & Conditions */}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 600 }}>
                Terms and Condition
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Terms and Conditions"
                value={termsAndConditions}
                onChange={(e) => setTermsAndConditions(e.target.value)}
              />
            </Form.Group>

            {/* Payment Type */}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 600 }}>Payment Type</Form.Label>
              <Form.Group>
                <Form.Check
                  inline
                  value="Cash"
                  checked={paymentType === "Cash"}
                  onChange={(e) => {
                    setPaymentType(e.target.value);
                  }}
                  label="Cash"
                  type="radio"
                />
                <Form.Check
                  inline
                  value="Cheque"
                  checked={paymentType === "Cheque"}
                  onChange={(e) => {
                    setPaymentType(e.target.value);
                  }}
                  label="Cheque"
                  type="radio"
                />
                <Form.Check
                  inline
                  value="P.D. Cheque"
                  checked={paymentType === "P.D. Cheque"}
                  onChange={(e) => {
                    setPaymentType(e.target.value);
                  }}
                  label="P.D. Cheque"
                  type="radio"
                />
                <Form.Check
                  inline
                  value="Credit LPO Basis"
                  checked={paymentType === "Credit LPO Basis"}
                  onChange={(e) => {
                    setPaymentType(e.target.value);
                  }}
                  label="Credit LPO Basis"
                  type="radio"
                />
              </Form.Group>
            </Form.Group>

            {/* VAT */}
            <Form.Group className="mb-3">
              <Form.Label style={{ fontWeight: 600 }}>
                Is VAT Applicable?
              </Form.Label>
              <Form.Check
                type="switch"
                checked={isVatApplicable}
                onChange={() => setIsVatApplicable(!isVatApplicable)}
              />
            </Form.Group>

            {/* Error Message */}
            <Form.Group>
              {errorMessage && (
                <p className="error__msg">
                  <BiErrorCircle />
                  &nbsp;{errorMessage}
                </p>
              )}
            </Form.Group>

            {/* Submit */}
            <Modal.Footer style={{ padding: 0, borderTop: "none" }}>
              <Button variant="success" type="submit">
                Add
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      <div className="container">
        <h3 className="title mb-3">Proforma Invoice</h3>
        <Button onClick={printProforma}>Print</Button>
        <br />
        <br />
        {!pfiCall || !customer || !agent ? (
          <p>Loading...</p>
        ) : (
          <div ref={pfiRef}>
            <div className="pfi_container">
              <PFIHeader
                customer={customer}
                agent={agent}
                attention={attention}
                pfiCall={pfiCall}
              />
              <ProductsTable
                pfiCall={pfiCall}
                isVatApplicable={isVatApplicable}
              />
              <BankDetails
                paymentType={paymentType}
                termsAndConditions={termsAndConditions}
              />
              <Clients />
              <PFIFooter />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
