import { useState, useEffect, useContext, memo } from "react";
import { db, timestamp } from "../utils/firebase";
import { AuthContext } from "../context/authContext";
import { v4 as uuidv4 } from "uuid";
import { Container, Form, Button, InputGroup } from "react-bootstrap";
import Loader from "../components/Loader/Loader";
import ProductGroupsTable from "../components/ProductGroups/ProductGroupsTable";
import { BiErrorCircle } from "react-icons/bi";
import "../styles/ProductGroups.css";
import { DataContext } from "../context/dataContext";

const ProductGroups = () => {
  const [productGroups, setProductGroups] = useState([]);
  const [newProductGroups, setNewProductGroups] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [customersInPg, setCustomersInPg] = useState({});
  const [ordersInPg, setordersInPg] = useState({});

  const { currentUser } = useContext(AuthContext);
  const { ordersData, customerData } = useContext(DataContext);

  const addProductGroup = (e) => {
    e.preventDefault();
    if (newProductGroups.length < 1) {
      setIsError(true);
      return;
    }
    setIsError(false);
    const id = uuidv4();
    db.collection("productgroups").doc(id).set({
      id,
      name: newProductGroups,
      createdAt: timestamp,
      updatedAt: timestamp,
    });
    setNewProductGroups("");
  };

  const calculateCustomersForProductgroups = () => {
    const custObj = {};
    customerData.forEach((d) => {
      d.interestedProductGroups.forEach((pg) => {
        if (!custObj[pg]) {
          custObj[pg] = 1;
        } else {
          custObj[pg] += 1;
        }
      });
    });
    setCustomersInPg(custObj);
  };

  const calculateOrdersForProductgroups = () => {
    const orderObj = {};
    ordersData.forEach((d) => {
      d.relatedProductGroups.forEach((pg) => {
        if (!orderObj[pg]) {
          orderObj[pg] = 1;
        } else {
          orderObj[pg] += 1;
        }
      });
    });
    setordersInPg(orderObj);
  };

  useEffect(() => {
    let abortController = new AbortController();
    db.collection("productgroups")
      .orderBy("name", "asc")
      .onSnapshot((snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setProductGroups(data);
        setIsLoading(false);
        calculateCustomersForProductgroups();
        calculateOrdersForProductgroups();
      });
    return () => abortController.abort();
  }, []);

  return (
    <Container className="container">
      <h3 className="title mb-3">Product Groups</h3>
      {(currentUser?.role === "admin" ||
        currentUser?.role === "super admin") && (
        <Form onSubmit={addProductGroup} style={{ marginBottom: 30 }}>
          <InputGroup className="productGroup__form">
            <Form.Control
              type="text"
              placeholder="Product group"
              value={newProductGroups}
              onChange={(e) => setNewProductGroups(e.target.value)}
            />
            <Button type="submit" variant="success">
              Add Product Group
            </Button>
          </InputGroup>
          {isError && (
            <p className="error__msg" style={{ marginTop: 10 }}>
              <BiErrorCircle />
              &nbsp;Product group cannot be empty!
            </p>
          )}
        </Form>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <ProductGroupsTable
          productGroups={productGroups}
          customersInPg={customersInPg}
          ordersInPg={ordersInPg}
        />
      )}
    </Container>
  );
};

export default memo(ProductGroups);
