const BankDetails = ({ paymentType, termsAndConditions }) => {
  return (
    <div className="bank_details">
      <h3>Payment Terms & Bank Details</h3>
      <p>
        <span>Payment Type:</span> {paymentType}
      </p>
      <p className="mt-1">
        <span>Terms & Conditions:</span> {termsAndConditions}
      </p>
      <table>
        <thead>
          <tr>
            <th>Bank Name</th>
            <th>Diamond Trust Bank</th>
            <th>NBC Bank</th>
            <th>CRDB</th>
            <th>Azania Bank</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Beneficiary</th>
            <td>Marango Ltd</td>
            <td>Marango Ltd</td>
            <td>Marango Ltd</td>
            <td>Marango Ltd</td>
          </tr>
          <tr>
            <th>Account No</th>
            <td>08021 28 001</td>
            <td>033 10 300 7365</td>
            <td>01504 3303 9000</td>
            <td>020000001722</td>
          </tr>
          <tr>
            <th>Branch</th>
            <td>Nyerere Branch</td>
            <td>Industrial</td>
            <td>Pugu Road</td>
            <td>IFB Branch</td>
          </tr>
          <tr>
            <th>Swift Code</th>
            <td>DTKETZTZ</td>
            <td>NLCBTZTX</td>
            <td>CORUTZTZ</td>
            <td>AZANTZTZ</td>
          </tr>
        </tbody>
      </table>
      <p>
        <span>MOBILE MONEY: TIGO PESA</span> - 0714 518 518 (Basharat Mawji)
        <br />
        <span>TIGO LIPA NAMBA :</span> - 5603174 (MARANGO LIMITED)
      </p>
      <p>All Cheques payable to "MARANGO LIMITED"</p>
      <p style={{ color: "rgba(0,0,0,0.4)", fontWeight: 500, marginTop: 10 }}>
        Prices valid for seven days. Subject to availability of goods and
        subject to final confirmation.
      </p>
      <p style={{ color: "rgba(0,0,0,0.4)", fontWeight: 500 }}>
        Collection to be arranged by buyer.
      </p>
    </div>
  );
};

export default BankDetails;
