import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
// import "firebase/compat/storage";

// live database
const firebaseConfig = {
  apiKey: "AIzaSyDXzG5gw1XBA_MdsIhhDusmAQYCXavR0RA",
  authDomain: "marango-crm.firebaseapp.com",
  projectId: "marango-crm",
  storageBucket: "marango-crm.appspot.com",
  messagingSenderId: "238509259948",
  appId: "1:238509259948:web:b1838449a0ec8ea6cab309",
  measurementId: "G-604E9D46EN",
};

// test database
// const firebaseConfig = {
//   apiKey: "AIzaSyBTW98XVf65NiPMtOLWlH-Z5eNkkRO7e3o",
//   authDomain: "marango-crm-test.firebaseapp.com",
//   projectId: "marango-crm-test",
//   storageBucket: "marango-crm-test.appspot.com",
//   messagingSenderId: "189508171921",
//   appId: "1:189508171921:web:6b38d55316f2e96776e730",
// };

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth();
// const storage = firebase.storage();

const fieldValue = firebase.firestore.FieldValue;
const timestamp = fieldValue.serverTimestamp();

export { db, auth, fieldValue, timestamp };
