import { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import NavigationBar from "../components/NavigationBar/NavigationBar";
import Home from "../pages/Home";
import Login from "../pages/Login";
import ScheduledCalls from "../pages/ScheduledCalls";
import SalesRepresentatives from "../pages/SalesRepresentatives";
import Customers from "../pages/Customers";
import ProductGroups from "../pages/ProductGroups";
import Roles from "../pages/Roles";
import ErrorPage from "../pages/ErrorPage";
import CustomerCallHistory from "../pages/CustomerCallHistory";
import SingleCallHistory from "../pages/SingleCallHistory";
import SalesRepCallHistory from "../pages/SalesRepCallHistory";
import SalesRepresentativePerformance from "../pages/SalesRepresentativePerformance";
import Orders from "../pages/Orders";
import Admins from "../pages/Admins";
import PFI from "../pages/PFI";

const Router = () => {
  const { currentUser } = useContext(AuthContext);
  return (
    <BrowserRouter>
      {currentUser && <NavigationBar />}
      <Routes>
        <Route
          exact
          path="/"
          element={currentUser ? <Home /> : <Navigate to="/login" />}
        />
        <Route
          exact
          path="/login"
          element={!currentUser ? <Login /> : <Navigate to="/" />}
        />
        <Route
          exact
          path="/scheduled-calls"
          element={currentUser ? <ScheduledCalls /> : <Navigate to="/" />}
        />
        <Route
          exact
          path="/customers"
          element={currentUser ? <Customers /> : <Navigate to="/" />}
        />
        <Route
          exact
          path="/customers/calls/:cid"
          element={currentUser ? <CustomerCallHistory /> : <Navigate to="/" />}
        />
        <Route
          exact
          path="/call/:cid"
          element={currentUser ? <SingleCallHistory /> : <Navigate to="/" />}
        />
        <Route
          exact
          path="/sales-representatives"
          element={
            currentUser?.role === "admin" ||
            currentUser?.role === "super admin" ? (
              <SalesRepresentatives />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          exact
          path="/admins"
          element={
            currentUser?.role === "super admin" ? (
              <Admins />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          exact
          path="/sales-representatives/calls/:cid"
          element={currentUser ? <SalesRepCallHistory /> : <Navigate to="/" />}
        />
        <Route
          exact
          path="/sales-representatives/performance/:cid"
          element={
            currentUser ? (
              <SalesRepresentativePerformance />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          exact
          path="/product-groups"
          element={currentUser ? <ProductGroups /> : <Navigate to="/login" />}
        />
        <Route
          exact
          path="/orders"
          element={currentUser ? <Orders /> : <Navigate to="/login" />}
        />
        <Route
          exact
          path="/roles"
          element={currentUser ? <Roles /> : <Navigate to="/login" />}
        />
        <Route
          exact
          path="/pfi/:id"
          element={currentUser ? <PFI /> : <Navigate to="/login" />}
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
