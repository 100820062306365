import { memo } from "react";
import { Table, Container, Modal, Row, Col, Badge } from "react-bootstrap";

const CustomerDetails = ({
  show,
  setShowDetails,
  customer,
  allInterestedProductGroups,
}) => {
  return (
    <Modal
      show={show}
      onHide={() => setShowDetails(false)}
      animation={false}
      centered
      className="big__modal"
    >
      <Modal.Header
        closeButton
        style={{ borderBottom: "none", paddingBottom: 0 }}
      >
        <Modal.Title>
          Customer Details <Badge bg="success">{customer?.customerType}</Badge>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container style={{ margin: 0 }}>
          {/* Customer details */}
          <Row className="mb-2">
            {customer?.customerType === "Individual" && (
              <>
                <h5 style={{ marginBottom: 10 }}>Personal Details</h5>
                <Table responsive striped bordered>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Email</th>
                      <th>Contact Number 1</th>
                      <th>Contact Number 2</th>
                      <th>DOB</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customer?.contactPersons.map((person) => (
                      <tr key={person.id}>
                        <td>{person.name ? person.name : "N/A"}</td>
                        <td>{person.role ? person.role : "N/A"}</td>
                        <td>{person.email ? person.email : "N/A"}</td>
                        <td>
                          {person.phoneNumber1 ? person.phoneNumber1 : "N/A"}
                        </td>
                        <td>
                          {person.phoneNumber2 ? person.phoneNumber2 : "N/A"}
                        </td>
                        <td>{person.dob ? person.dob : "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Row>

          {/* Company Details */}
          <Row className="mb-2">
            {customer?.customerType === "Company" && (
              <>
                <h5 style={{ marginBottom: 5 }}>Company Name:</h5>
                <p>{customer?.companyName ? customer?.companyName : "N/A"}</p>
                <h5 style={{ marginBottom: 10 }}>Employee Details:</h5>
                <Table responsive striped bordered>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Role</th>
                      <th>Email</th>
                      <th>Contact Number 1</th>
                      <th>Contact Number 2</th>
                      <th>DOB</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customer?.contactPersons.map((person) => (
                      <tr key={person.id}>
                        <td>{person.name ? person.name : "N/A"}</td>
                        <td>{person.role ? person.role : "N/A"}</td>
                        <td>{person.email ? person.email : "N/A"}</td>
                        <td>
                          {person.phoneNumber1 ? person.phoneNumber1 : "N/A"}
                        </td>
                        <td>
                          {person.phoneNumber2 ? person.phoneNumber2 : "N/A"}
                        </td>
                        <td>{person.dob ? person.dob : "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            )}
          </Row>

          {/* Address Details */}
          <Row className="mb-2">
            <h5 style={{ marginBottom: 5 }}>Address Details</h5>
            <p style={{ marginBottom: 0 }}>
              <b>Physical Address: </b>
              {customer.address?.physical_address
                ? customer.address?.physical_address
                : "N/A"}
            </p>
            <Col>
              <p>
                <b>City: </b>
                {customer?.address?.city ? customer?.address?.city : "N/A"}
              </p>
            </Col>
            <Col>
              <p>
                <b>Country: </b>
                {customer?.address.country ? customer?.address?.country : "N/A"}
              </p>
            </Col>
          </Row>

          {/* TIN and VRN Numbers */}
          <Row className="mb-2">
            <Col>
              <p>
                <b>TIN Number: </b>
                {customer?.TIN ? customer?.TIN : "N/A"}
              </p>
            </Col>
            <Col>
              <p>
                <b>VRN Number: </b>
                {customer?.VRN ? customer?.VRN : "N/A"}
              </p>
            </Col>
          </Row>

          {/* Interested Product Groups */}
          <Row className="mb-2">
            <h5 style={{ marginBottom: 5 }}>Interested Product Groups</h5>
            <p>
              {customer?.interestedProductGroups
                ?.map((ipg) => allInterestedProductGroups[ipg])
                .join(", ")}
            </p>
          </Row>

          {/* Other Product Groups */}
          <Row className="mb-2">
            <h5 style={{ marginBottom: 5 }}>Other Product Groups</h5>
            <p>
              {customer?.otherProductGroups
                ? customer?.otherProductGroups
                : "N/A"}
            </p>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default memo(CustomerDetails);
