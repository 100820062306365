import { useContext, memo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { Button, Form } from "react-bootstrap";
import { BsTrashFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { db } from "../../utils/firebase";

const CustomerRow = ({
  customer,
  index,
  setIsCall,
  setIsEditingCustomer,
  setSelectedCustomer,
  setShowConfirmBox,
  setShowDetails,
  allInterestedProductGroups,
  searchTerm,
  salesReps,
}) => {
  const [addedBy, setAddedBy] = useState(undefined);
  const [isEditingSalesRep, setIsEditingSalesRep] = useState(false);
  const [selectedSalesRep, setSelectedSalesRep] = useState("");
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChangeSalesRep = () => {
    db.collection("customers")
      .doc(customer.id)
      .update({
        addedById: selectedSalesRep,
        addedByRole: "sales representative",
      })
      .then(() => {
        setSelectedSalesRep("");
        setIsEditingSalesRep(false);
        alert("Sales Representative changed!");
        window.location.reload();
      });
  };

  const printInterestedProductGroups = () => {
    const ipgs = customer?.interestedProductGroups
      ?.map((ipg) => allInterestedProductGroups[ipg])
      .join(", ");
    return ipgs.length > 130 ? ipgs.slice(0, 130) + "..." : ipgs;
  };

  useEffect(() => {
    setAddedBy(salesReps.find((s) => s.id === customer?.addedById));
  }, [customer?.addedById, salesReps]);

  return (
    <>
      {(addedBy?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        customer?.companyName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        customer?.contactPersons[0]?.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        customer?.TIN.includes(searchTerm) ||
        customer?.contactPersons[0]?.phoneNumber1.includes(searchTerm)) && (
        <tr key={customer.id}>
          <td>{index + 1}.</td>
          <td>
            {customer?.companyName
              ? customer?.companyName
              : customer?.contactPersons[0]?.name}
          </td>
          <td>{customer?.contactPersons[0]?.phoneNumber1}</td>
          <td>{customer?.customerType}</td>
          <td>
            <div className="actions__btn__container">
              <button
                onClick={() => {
                  setIsEditingCustomer(true);
                  setSelectedCustomer(index);
                }}
                className="action__btn"
              >
                <MdModeEdit />
              </button>
              &nbsp;&nbsp;
              {(currentUser.role === "admin" ||
                currentUser.role === "super admin") && (
                <>
                  <button
                    onClick={() => {
                      setSelectedCustomer(index);
                      setShowConfirmBox(true);
                    }}
                    className="action__btn"
                  >
                    <BsTrashFill />
                  </button>
                  &nbsp;&nbsp;
                </>
              )}
              {currentUser.role === "sales representative" && (
                <button
                  onClick={() => {
                    setIsCall(true);
                    setSelectedCustomer(index);
                  }}
                  className="action__btn"
                >
                  <IoCall />
                </button>
              )}
            </div>
          </td>
          <td>
            <Button
              variant="success"
              onClick={() => {
                setShowDetails(true);
                setSelectedCustomer(index);
              }}
            >
              View
            </Button>
          </td>
          <td>
            <Button
              variant="success"
              onClick={() => navigate(`/customers/calls/${customer.id}`)}
            >
              View
            </Button>
          </td>
          <td>{printInterestedProductGroups()}</td>
          <td>
            {isEditingSalesRep && (
              <div>
                <Form.Select
                  value={selectedSalesRep}
                  onChange={(e) => setSelectedSalesRep(e.target.value)}
                >
                  <option>Select</option>
                  {salesReps?.map((s) => (
                    <option value={s.id}>
                      {s.name} ({s.role})
                    </option>
                  ))}
                </Form.Select>
                <div className="mt-2">
                  <Button
                    onClick={() => {
                      setIsEditingSalesRep(false);
                      setSelectedSalesRep("");
                    }}
                    variant="light"
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    variant="success"
                    disabled={!selectedSalesRep}
                    onClick={handleChangeSalesRep}
                  >
                    Change
                  </Button>
                </div>
              </div>
            )}

            {!isEditingSalesRep && (
              <>
                {customer?.addedByRole === "super admin"
                  ? "Super Admin"
                  : `${addedBy?.name} (${customer?.addedByRole})`}
                {(currentUser.role === "admin" ||
                  currentUser.role === "super admin") && (
                  <button
                    onClick={() => {
                      setSelectedCustomer(index);
                      setIsEditingSalesRep(true);
                    }}
                    className="action__btn"
                  >
                    <MdModeEdit />
                  </button>
                )}
              </>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

export default memo(CustomerRow);
