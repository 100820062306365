import { createContext, useState, useEffect } from "react";
import { db } from "../utils/firebase";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [customerData, setCustomerData] = useState(undefined);
  const [ordersData, setOrdersData] = useState(undefined);
  const [callsData, setCallsData] = useState(undefined);
  const [activeCallData, setActiveCallData] = useState(undefined);

  useEffect(() => {
    const fetchCustomerData = async () => {
      db.collection("customers")
        .orderBy("createdAt", "asc")
        .get()
        .then((data) => setCustomerData(data.docs.map((d) => d.data())));
    };
    const fetchOrdersData = async () => {
      db.collection("orders")
        .orderBy("createdAt", "asc")
        .get()
        .then((data) => setOrdersData(data.docs.map((d) => d.data())));
    };
    const fetchCallsData = async () => {
      db.collection("callhistory")
        .orderBy("createdAt", "asc")
        .get()
        .then((data) => setCallsData(data.docs.map((d) => d.data())));
    };
    const fetchActiveCallsData = async () => {
      db.collection("callhistory")
        .orderBy("createdAt", "asc")
        .get()
        .then((data) => setActiveCallData(data.docs.map((d) => d.data())));
    };
    fetchCustomerData();
    fetchOrdersData();
    fetchCallsData();
    fetchActiveCallsData();
  }, []);

  return (
    <DataContext.Provider
      value={{
        customerData,
        ordersData,
        callsData,
        activeCallData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
