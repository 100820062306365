/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, memo, useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { db } from "../../utils/firebase";
import Loader from "../Loader/Loader";
import OrderTable from "./OrderTable";
import dateFormat from "dateformat";
import { Badge, Button, Container, Form, InputGroup } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";

const OrdersFailed = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [startDate, setStartDate] = useState(
    dateFormat(Date.now() - 27 * 86400000, "yyyy-mm-dd")
  );
  const [endDate, setEndDate] = useState(
    dateFormat(Date.now() + 86400000, "yyyy-mm-dd")
  );
  const { currentUser } = useContext(AuthContext);

  const fetchOrders = () => {
    if (currentUser?.role === "sales representative") {
      db.collection("calls")
        .where("status", "==", "No Order")
        .where("isCallback", "==", false)
        .where("isRequirement", "==", true)
        .where("createdAt", ">", new Date(startDate))
        .where("createdAt", "<", new Date(endDate))
        .where("agent_id", "==", currentUser.id)
        .orderBy("createdAt", "desc")
        .onSnapshot((snapshot) => {
          setOrders(snapshot.docs.map((doc) => doc.data()));
          setIsLoading(false);
        });
    } else {
      db.collection("calls")
        .where("status", "==", "No Order")
        .where("isCallback", "==", false)
        .where("isRequirement", "==", true)
        .where("createdAt", ">", new Date(startDate))
        .where("createdAt", "<", new Date(endDate))
        .orderBy("createdAt", "desc")
        .onSnapshot((snapshot) => {
          setOrders(snapshot.docs.map((doc) => doc.data()));
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    fetchOrders();
    return () => abortController.abort();
  }, []);

  return (
    <Container className="customer__container">
      <div className="customer__title">
        <h3 className="title">
          Failed Orders <Badge bg="danger">{orders.length} orders</Badge>
        </h3>
        <Button variant="dark" onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? "Hide Filters" : "Filters"}
        </Button>
      </div>

      {showFilters && (
        <div className="filters">
          <h3>Filters</h3>
          <div className="mt-4 mb-1 d-flex align-items-center">
            <Form.Group className="d-flex align-items-center">
              <Form.Label style={{ fontWeight: "500", margin: "0px 10px 0 0" }}>
                Start Date
              </Form.Label>
              <Form.Control
                style={{ width: 200 }}
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Form.Group className="d-flex align-items-center">
              <Form.Label style={{ fontWeight: "500", margin: "0px 10px 0 0" }}>
                End Date
              </Form.Label>
              <Form.Control
                style={{ width: 200 }}
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
          </div>
          <Button
            variant="dark"
            className="mb-2 mt-4"
            onClick={fetchOrders}
            disabled={!startDate || !endDate}
          >
            Apply
          </Button>
        </div>
      )}

      <InputGroup className="customer__Search">
        <InputGroup.Text className="bg-white">
          <BsSearch />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Search Orders"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
      {isLoading ? (
        <Loader />
      ) : (
        <OrderTable orders={orders} searchTerm={searchTerm} />
      )}
    </Container>
  );
};

export default memo(OrdersFailed);
