/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { getDateNDaysAgo } from "../../utils/helperFunctions";
import { daysArr } from "../../utils/days";
import dateFormat from "dateformat";

export default function IndividualsGraph({ data }) {
  const [days, setDays] = useState(7);
  const [graphData, setGraphData] = useState(undefined);
  const [total, setTotal] = useState(0);

  const filterData = () => {
    let dataObj = [];
    const temp = data?.filter((d) => {
      return new Date(d.createdAt.seconds * 1000) >= getDateNDaysAgo(days);
    });
    setTotal(temp.length);

    if (parseInt(days) === 7) {
      let tempDays = {};
      temp.forEach((d) => {
        const day = daysArr[new Date(d.createdAt.seconds * 1000).getDay()];
        if (!tempDays[day]) {
          tempDays[day] = 1;
        } else {
          tempDays[day] += 1;
        }
      });
      for (let i = 0; i < 7; i++) {
        const dayIndex = getDateNDaysAgo(i).getDay();
        dataObj.push({
          name: daysArr[dayIndex],
          "Individuals Added": tempDays[daysArr[dayIndex]] || 0,
        });
      }
    } else if (parseInt(days) === 30) {
      let tempDays = {};
      temp.forEach((d) => {
        const day = new Date(d.createdAt.seconds * 1000).toLocaleDateString();
        if (!tempDays[day]) {
          tempDays[day] = 1;
        } else {
          tempDays[day] += 1;
        }
      });
      for (let i = 0; i < 30; i++) {
        const dayString = getDateNDaysAgo(i).toLocaleDateString();
        dataObj.push({
          name: dateFormat(dayString, "dd mmm yy"),
          "Individuals Added": tempDays[dayString] || 0,
        });
      }
    } else if (parseInt(days) === 90) {
      let tempDays = {};
      temp.forEach((d) => {
        const month = dateFormat(
          new Date(d.createdAt.seconds * 1000),
          "mmm yy"
        );
        if (!tempDays[month]) {
          tempDays[month] = 1;
        } else {
          tempDays[month] += 1;
        }
      });
      for (let i = 0; i < Object.keys(tempDays).length; i++) {
        dataObj.push({
          name: Object.keys(tempDays)[i],
          "Individuals Added": Object.values(tempDays)[i] || 0,
        });
      }
      dataObj = dataObj.reverse();
    } else if (parseInt(days) === 180) {
      let tempDays = {};
      temp.forEach((d) => {
        const month = dateFormat(
          new Date(d.createdAt.seconds * 1000),
          "mmm yy"
        );
        if (!tempDays[month]) {
          tempDays[month] = 1;
        } else {
          tempDays[month] += 1;
        }
      });
      for (let i = 0; i < 6; i++) {
        const month = dateFormat(getDateNDaysAgo(i * 31), "mmm yy");
        dataObj.push({
          name: month,
          "Individuals Added": tempDays[month] || 0,
        });
      }
    }
    setGraphData(dataObj.reverse());
  };

  useEffect(() => {
    filterData();
  }, [days]);

  return (
    <Card className="p-2">
      <div className="chart-title">
        <h2>Total Individuals Added ({total})</h2>
        <Form.Select
          value={days}
          onChange={(e) => setDays(e.target.value)}
          style={{ width: 200 }}
        >
          <option disabled value={-1}>
            Select Days
          </option>
          <option value={7}>Past 7 days</option>
          <option value={30}>Past 30 days</option>
          <option value={90}>Past 3 months</option>
          <option value={180}>Past 6 months</option>
          {/* <option value={-1}>So Far</option> */}
        </Form.Select>
      </div>
      {graphData && (
        <LineChart
          width={600}
          height={300}
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="Individuals Added"
            stroke="#82ca9d"
            strokeWidth={3}
          />
        </LineChart>
      )}
    </Card>
  );
}
