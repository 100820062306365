import { memo, useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { Table } from "react-bootstrap";
import ScheduledCallsRow from "./ScheduledCallsRow";
// import { HiOutlineEmojiSad } from "react-icons/hi";

const ScheduledCallsTable = ({ searchTerm, scheduledCalls }) => {
  const { currentUser } = useContext(AuthContext);
  return (
    <Table responsive striped bordered>
      <thead>
        <tr>
          <th>Sr No.</th>
          <th>Name</th>
          <th>Phone Number</th>
          <th>Status</th>
          <th>Scheduled Time</th>
          <th>Discussion</th>
          <th>Actions</th>
          {(currentUser?.role === "admin" ||
            currentUser?.role === "super admin") && (
            <th>Sales Representative</th>
          )}
          <th>Details</th>
          <th>Call History</th>
          <th>Proforma</th>
        </tr>
      </thead>
      <tbody>
        {scheduledCalls.length === 0 ? (
          <tr>
            {/* <td colSpan={9}>
              <div className="no__results">
                <HiOutlineEmojiSad />
                <p>No results matching your filter</p>
              </div>
            </td> */}
          </tr>
        ) : (
          scheduledCalls?.map((call, index) => (
            <ScheduledCallsRow
              call={call}
              key={call.id}
              index={index + 1}
              searchTerm={searchTerm}
            />
          ))
        )}
      </tbody>
    </Table>
  );
};

export default memo(ScheduledCallsTable);
