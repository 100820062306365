import { memo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../utils/firebase";
import EditSalesRepresentatives from "./EditSalesRepresentatives";
import ConfirmBox from "../ConfirmBox/ConfirmBox";
import { Table, Button } from "react-bootstrap";
import { BsTrashFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";

const SalesRepresentativesTable = ({ salesRepresentatives }) => {
  const [isEditingSalesRepresentative, setIsEditingSalesRepresentative] =
    useState(false);
  const [selectedSalesRepresentative, setSelectedSalesRepresentative] =
    useState(-1);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const navigate = useNavigate();

  const deleteSalesRepresentative = useCallback((id) => {
    db.collection("users").doc(id).delete();
    setShowConfirmBox(false);
  }, []);

  return (
    <>
      {isEditingSalesRepresentative && (
        <EditSalesRepresentatives
          salesRepresentative={
            salesRepresentatives[selectedSalesRepresentative]
          }
          setIsEditingSalesRepresentative={setIsEditingSalesRepresentative}
          isEditingSalesRepresentative={isEditingSalesRepresentative}
        />
      )}
      {showConfirmBox && (
        <ConfirmBox
          show={showConfirmBox}
          setShowConfirmBox={setShowConfirmBox}
          deleteFunction={deleteSalesRepresentative}
          id={salesRepresentatives[selectedSalesRepresentative]?.id}
          text="Are you sure you want to delete this?"
        />
      )}
      <Table responsive striped bordered>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Name</th>
            <th>Username</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>DOB</th>
            <th>Actions</th>
            <th>History</th>
            <th>Performance</th>
          </tr>
        </thead>
        <tbody>
          {salesRepresentatives?.map((sr, index) => (
            <tr key={sr.id}>
              <td>{index + 1}.</td>
              <td>{sr.name}</td>
              <td>{sr.username}</td>
              <td>{sr.email}</td>
              <td>
                {sr.phonenumber}
                {sr.phonenumber2 && `, ${sr.phonenumber2}`}
                {sr.phonenumber3 && `, ${sr.phonenumber3}`}
              </td>
              <td>{sr.dob || "Not Added"}</td>
              <td>
                <div className="actions__btn__container">
                  <button
                    onClick={() => {
                      setIsEditingSalesRepresentative(true);
                      setSelectedSalesRepresentative(index);
                    }}
                    className="action__btn"
                  >
                    <MdModeEdit />
                  </button>
                  &nbsp;&nbsp;
                  <button
                    onClick={() => {
                      setSelectedSalesRepresentative(index);
                      setShowConfirmBox(true);
                    }}
                    className="action__btn"
                  >
                    <BsTrashFill />
                  </button>
                </div>
              </td>
              <th>
                <Button
                  variant="success"
                  onClick={() =>
                    navigate(`/sales-representatives/calls/${sr.id}`)
                  }
                >
                  View
                </Button>
              </th>
              <th>
                <Button
                  variant="success"
                  onClick={() =>
                    navigate(`/sales-representatives/performance/${sr.id}`)
                  }
                >
                  View
                </Button>
              </th>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default memo(SalesRepresentativesTable);
