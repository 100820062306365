const Clients = () => {
  return (
    <div className="image_container">
      {[...Array(16).keys()].map((image) => (
        <div key={image}>
          <img src={`/clients/${image + 1}.jpeg`} alt="clients" />
        </div>
      ))}
    </div>
  );
};

export default Clients;
