import OrdersConfirmed from "../components/Orders/OrdersConfirmed";
import OrdersFailed from "../components/Orders/OrdersFailed";
import { Container, Tabs, Tab } from "react-bootstrap";

const Orders = () => {
  return (
    <Container>
      <Tabs
        defaultActiveKey="confirmed"
        className="mb-0"
        id="noanim-tab-example"
      >
        <Tab eventKey="confirmed" title="Confirmed Orders">
          <OrdersConfirmed />
        </Tab>
        <Tab eventKey="failed" title="Failed Orders">
          <OrdersFailed />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Orders;
