import logo from "../../assets/logo.png";
import { FaInstagram, FaFacebookSquare, FaYoutube } from "react-icons/fa";

const PFIFooter = () => {
  return (
    <div className="pfi_footer">
      <div className="contact_details">
        <div className="image">
          <a href="http://www.marango.co.tz/" target="_blank" rel="noreferrer">
            <img src={logo} alt="logo" width={80} />
          </a>
        </div>
        <div className="contacts">
          <p>
            <span>Hotline: </span>+255 714 300 027 / +255 714 200 003 / +255 658 239 996 / +255 714
            200 002
          </p>
          &nbsp;|&nbsp;
          <p>
            <span>E: </span>info@marango.co.tz
          </p>
        </div>
        <div className="social">
          <a href="https://www.instagram.com/marango_limited/" target="_blank" rel="noreferrer">
            <FaInstagram color="#F56040" fontSize={22} />
          </a>
          <a href="https://www.facebook.com/marangotz" target="_blank" rel="noreferrer">
            <FaFacebookSquare color="#0165E1" fontSize={22} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCvkz1akOWUETw8nZC0RIPog"
            target="_blank"
            rel="noreferrer"
          >
            <FaYoutube color="#FF0000" fontSize={22} />
          </a>
        </div>
      </div>
      <div>
        <p className="services">
          Waterproofing | Construction Chemicals | Steel | Drywall | Insulation | Plywoods | Cement
          | Construction Machineries | Fillers | Doors | MDF | Plumbing | Adhesives | Gypsum |
          Aluminium Panels | Hollow Sections | Black Pipes | BRC | Sanitary | Paints | PPE |
          Ceilings | EPS
        </p>
      </div>
    </div>
  );
};

export default PFIFooter;
