import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../utils/firebase";
import dateFormat from "dateformat";
import Loader from "../Loader/Loader";
import { Container, Table, Badge } from "react-bootstrap";

const CustomersAdded = ({ salesRepresentative }) => {
  const [customers, setCustomers] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const { cid } = useParams();

  useEffect(() => {
    let abortController = new AbortController();
    db.collection("customers")
      .where("addedById", "==", cid)
      .orderBy("companyName", "asc")
      .onSnapshot((snapshot) => {
        setCustomers(snapshot.docs.map((doc) => doc.data()));
        setIsLoading(false);
      });
    return () => abortController.abort();
  }, [cid]);

  return (
    <Container className="customer__container mt-5">
      <div className="customer__title">
        <h4 className="title mb-3">
          Customers added by {salesRepresentative?.name}&nbsp;
          <Badge bg="success">{customers?.length} customers</Badge>
        </h4>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table responsive striped bordered>
          <thead>
            <tr>
              <th>Sr No.</th>
              <th>Customer Name</th>
              <th>Customer Type</th>
              <th>Added On</th>
            </tr>
          </thead>
          <tbody>
            {customers?.map((customer, index) => (
              <tr key={customer.id}>
                <td>{index + 1}.</td>
                <td>
                  {customer?.companyName
                    ? customer?.companyName
                    : customer?.contactPersons[0]?.name}
                </td>
                <td>{customer?.customerType}</td>
                <td>
                  {dateFormat(
                    new Date(customer?.createdAt?.seconds * 1000),
                    "ddd, dd mmm  yyyy, hh:MM TT"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default CustomersAdded;
