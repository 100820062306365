/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext, memo } from "react";
import { db } from "../utils/firebase";
import { AuthContext } from "../context/authContext";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import Loader from "../components/Loader/Loader";
import ScheduledCallsTable from "../components/ScheduledCalls/ScheduledCallsTable";
import { BsSearch } from "react-icons/bs";
import "../styles/ScheduledCalls.css";

const ScheduledCalls = () => {
  const [AllScheduledCalls, setAllScheduledCalls] = useState([]);
  const [scheduledCalls, setScheduledCalls] = useState([]);
  const [filters, setFilters] = useState(["Lead", "No Order", "Not Available"]);
  const [isMissedCallSelected, setIsMissedCallSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { currentUser } = useContext(AuthContext);

  const applyFilters = () => {
    let filteredCalls = [];

    if (selectedDate) {
      filteredCalls = AllScheduledCalls.filter(
        (call) =>
          new Date(call.callback_date?.seconds * 1000).toLocaleDateString() ===
          new Date(selectedDate).toLocaleDateString()
      );
    } else if (isMissedCallSelected) {
      filteredCalls = AllScheduledCalls.filter(
        (call) => new Date(call.callback_date?.seconds * 1000) < new Date()
      );
    } else if (startDate && endDate) {
      filteredCalls = AllScheduledCalls.filter(
        (call) =>
          new Date(call.callback_date?.seconds * 1000) < new Date(endDate) &&
          new Date(call.callback_date?.seconds * 1000) > new Date(startDate)
      );
    }

    if (selectedDate && isMissedCallSelected) {
      filteredCalls = AllScheduledCalls.filter(
        (call) =>
          new Date(call.callback_date?.seconds * 1000) < new Date() &&
          new Date(call.callback_date?.seconds * 1000).toLocaleDateString() ===
            new Date(selectedDate).toLocaleDateString()
      );
    } else if (isMissedCallSelected && startDate && endDate) {
      filteredCalls = AllScheduledCalls.filter(
        (call) =>
          new Date(call.callback_date?.seconds * 1000) < new Date() &&
          new Date(call.callback_date?.seconds * 1000) < new Date(endDate) &&
          new Date(call.callback_date?.seconds * 1000) > new Date(startDate)
      );
    } else if (selectedDate && startDate && endDate) {
      filteredCalls = AllScheduledCalls.filter(
        (call) =>
          new Date(call.callback_date?.seconds * 1000).toLocaleDateString() ===
            new Date(selectedDate).toLocaleDateString() &&
          new Date(call.callback_date?.seconds * 1000) < new Date(endDate) &&
          new Date(call.callback_date?.seconds * 1000) > new Date(startDate)
      );
    }

    if (!isMissedCallSelected && !selectedDate && !(startDate && endDate)) {
      filteredCalls = AllScheduledCalls.filter(
        (call) =>
          filters.includes(call?.status) &&
          new Date(call.callback_date?.seconds * 1000) > new Date() &&
          new Date(call.callback_date?.seconds * 1000).toLocaleDateString() !==
            new Date(selectedDate).toLocaleDateString()
      );
    }

    filteredCalls = filteredCalls.filter((call) =>
      filters.includes(call?.status)
    );

    setScheduledCalls(filteredCalls);
  };

  const handleChange = (e) => {
    if (filters.length === 1 && filters.includes(e.target.value)) {
      return;
    } else if (filters.includes(e.target.value)) {
      setFilters(filters.filter((filter) => filter !== e.target.value));
    } else {
      setFilters([...filters, e.target.value]);
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    const fetchCalls = () => {
      if (currentUser.role === "admin" || currentUser.role === "super admin") {
        db.collection("calls")
          .where("isCallback", "==", true)
          .orderBy("callback_date", "asc")
          .onSnapshot((snapshot) => {
            setAllScheduledCalls(snapshot.docs.map((doc) => doc.data()));
            setScheduledCalls(
              snapshot.docs
                .map((doc) => doc.data())
                .filter(
                  (call) =>
                    new Date(call.callback_date?.seconds * 1000) > new Date()
                )
            );
            setIsLoading(false);
          });
      } else {
        db.collection("calls")
          .where("isCallback", "==", true)
          .where("agent_id", "==", currentUser.id)
          .onSnapshot((snapshot) => {
            setAllScheduledCalls(snapshot.docs.map((doc) => doc.data()));
            setScheduledCalls(
              snapshot.docs
                .map((doc) => doc.data())
                .filter(
                  (call) =>
                    new Date(call.callback_date?.seconds * 1000) > new Date()
                )
            );
            setIsLoading(false);
          });
      }
    };
    fetchCalls();
    return () => abortController.abort();
  }, []);

  return (
    <Container>
      <div className="schedule__calls__header">
        <h3 className="title">Scheduled Calls</h3>
        <Button variant="dark" onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? "Hide Filters" : "Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filters">
          <h3>Filters</h3>
          <Form.Group>
            <Form.Check
              inline
              value="Lead"
              label="Lead"
              type="checkbox"
              checked={filters.includes("Lead")}
              onChange={handleChange}
            />
            <Form.Check
              inline
              value="No Order"
              label="No Order"
              type="checkbox"
              checked={filters.includes("No Order")}
              onChange={handleChange}
            />
            <Form.Check
              inline
              value="Not Available"
              label="Not Available"
              type="checkbox"
              checked={filters.includes("Not Available")}
              onChange={handleChange}
            />
            <Form.Check
              inline
              value="Missed Calls"
              label="Missed Calls"
              type="checkbox"
              checked={isMissedCallSelected}
              onChange={() => setIsMissedCallSelected(!isMissedCallSelected)}
            />
            <Form.Group className="d-flex align-items-center mt-2">
              <Form.Label
                style={{ marginRight: 10, marginBottom: 0, fontWeight: "500" }}
              >
                Date
              </Form.Label>
              <Form.Control
                style={{ width: 200 }}
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </Form.Group>
            <div className="mt-4 mb-1 d-flex align-items-center">
              <Form.Group className="d-flex align-items-center">
                <Form.Label
                  style={{ fontWeight: "500", margin: "0px 10px 0 0" }}
                >
                  Start Date
                </Form.Label>
                <Form.Control
                  style={{ width: 200 }}
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </Form.Group>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Form.Group className="d-flex align-items-center">
                <Form.Label
                  style={{ fontWeight: "500", margin: "0px 10px 0 0" }}
                >
                  End Date
                </Form.Label>
                <Form.Control
                  style={{ width: 200 }}
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </Form.Group>
            </div>
          </Form.Group>
          <div className="filter__buttons">
            <Button variant="dark" onClick={applyFilters} className="btn">
              Apply Filters
            </Button>
          </div>
        </div>
      )}

      <InputGroup className="customer__Search">
        <InputGroup.Text className="bg-white">
          <BsSearch />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Search Calls"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>

      {isLoading ? (
        <Loader />
      ) : (
        <ScheduledCallsTable
          scheduledCalls={scheduledCalls}
          searchTerm={searchTerm}
        />
      )}
    </Container>
  );
};

export default memo(ScheduledCalls);
