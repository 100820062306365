import { memo } from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader__container">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default memo(Loader);
