import { useState, useEffect, memo } from "react";
import { db, timestamp } from "../utils/firebase";
import { v4 as uuidv4 } from "uuid";
import { Button, Container, Form, InputGroup } from "react-bootstrap";
import Loader from "../components/Loader/Loader";
import RolesTable from "../components/Roles/RolesTable";
import { BiErrorCircle } from "react-icons/bi";
import "../styles/Roles.css";

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const addRole = (e) => {
    e.preventDefault();
    if (newRole.length < 1) {
      setIsError(true);
      return;
    }
    setIsError(false);
    const id = uuidv4();
    db.collection("roles").doc(id).set({
      id,
      name: newRole,
      createdAt: timestamp,
      updatedAt: timestamp,
    });
    setNewRole("");
  };

  useEffect(() => {
    let abortController = new AbortController();
    db.collection("roles").onSnapshot((snapshot) => {
      setRoles(snapshot.docs.map((doc) => doc.data()));
      setIsLoading(false);
    });
    return () => abortController.abort();
  }, []);

  return (
    <Container>
      <h3 className="title">Roles</h3>
      <Form onSubmit={addRole} style={{ marginBottom: 30 }}>
        <InputGroup className="role__form">
          <Form.Control
            type="text"
            placeholder="Role"
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
          />
          <Button type="submit" variant="success">
            Add Role
          </Button>
        </InputGroup>
        {isError && (
          <p className="error__msg" style={{ marginTop: 10 }}>
            <BiErrorCircle />
            &nbsp;Role cannot be empty!
          </p>
        )}
      </Form>
      {isLoading ? <Loader /> : <RolesTable roles={roles} />}
    </Container>
  );
};

export default memo(Roles);
