import { useState, useEffect, memo } from "react";
import { useParams } from "react-router-dom";
import { db } from "../utils/firebase";
import Loader from "../components/Loader/Loader";
import { Badge, Button, Container, Form } from "react-bootstrap";
import CustomerCallHistoryTable from "../components/History/CustomerCallHistoryTable";
import "../styles/Customers.css";

const CustomerCallHistory = () => {
  const [customerCalls, setCustomersCalls] = useState([]);
  const [filteredCustomerCalls, setFilteredCustomerCalls] = useState([]);
  const [filters, setFilters] = useState([
    "Lead",
    "No Order",
    "Not Available",
    "Order Confirmed",
  ]);
  const [selectedDate, setSelectedDate] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { cid } = useParams();

  const applyFilters = () => {
    let filteredCalls = [];
    if (selectedDate) {
      filteredCalls = customerCalls
        .filter((call) => {
          // console.log(
          //   new Date(call.createdAt?.seconds * 1000).toLocaleDateString(),
          //   new Date(selectedDate).toLocaleDateString()
          // );
          return (
            new Date(call.createdAt?.seconds * 1000).toLocaleDateString() ===
            new Date(selectedDate).toLocaleDateString()
          );
        })
        .filter((call) => filters.includes(call?.status));
    } else {
      filteredCalls = customerCalls.filter((call) =>
        filters.includes(call?.status)
      );
    }
    setFilteredCustomerCalls(filteredCalls);
  };

  const handleChange = (e) => {
    if (filters.length === 1 && filters.includes(e.target.value)) {
      return;
    } else if (filters.includes(e.target.value)) {
      setFilters(filters.filter((filter) => filter !== e.target.value));
    } else {
      setFilters([...filters, e.target.value]);
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    const fetchCalls = () => {
      db.collection("callhistory")
        .where("customer_id", "==", cid)
        .orderBy("createdAt", "desc")
        .onSnapshot((snapshot) => {
          setCustomersCalls(snapshot.docs.map((doc) => doc.data()));
          setFilteredCustomerCalls(snapshot.docs.map((doc) => doc.data()));
          setIsLoading(false);
        });
    };
    fetchCalls();
    return () => abortController.abort();
  }, [cid]);

  return (
    <Container className="customer__container">
      <div className="customer__title">
        <h3 className="title">
          Customer Call History{" "}
          <Badge bg="success">{filteredCustomerCalls?.length} calls</Badge>
        </h3>
        <Button variant="dark" onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? "Hide Filters" : "Filters"}
        </Button>
      </div>
      {showFilters && (
        <div className="filters">
          <h3>Filters</h3>
          <Form.Group>
            <Form.Check
              inline
              value="Lead"
              label="Lead"
              type="checkbox"
              checked={filters.includes("Lead")}
              onChange={handleChange}
            />
            <Form.Check
              inline
              value="No Order"
              label="No Order"
              type="checkbox"
              checked={filters.includes("No Order")}
              onChange={handleChange}
            />
            <Form.Check
              inline
              value="Not Available"
              label="Not Available"
              type="checkbox"
              checked={filters.includes("Not Available")}
              onChange={handleChange}
            />
            <Form.Check
              inline
              value="Order Confirmed"
              label="Order Confirmed"
              type="checkbox"
              checked={filters.includes("Order Confirmed")}
              onChange={handleChange}
            />
            <Form.Group className="d-flex align-items-center mt-3">
              <Form.Label style={{ marginRight: 10, marginBottom: 0 }}>
                Date
              </Form.Label>
              <Form.Control
                style={{ width: 200 }}
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </Form.Group>
          </Form.Group>
          <div className="filter__buttons">
            <Button variant="dark" onClick={applyFilters} className="btn">
              Apply Filters
            </Button>
          </div>
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <CustomerCallHistoryTable customerCalls={filteredCustomerCalls} />
      )}
    </Container>
  );
};

export default memo(CustomerCallHistory);
