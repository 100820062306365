import { useState, useContext, memo, useCallback } from "react";
import { db } from "../../utils/firebase";
import { AuthContext } from "../../context/authContext";
import Table from "react-bootstrap/Table";
import EditRoles from "./EditRoles";
import ConfirmBox from "../ConfirmBox/ConfirmBox";
import { BsTrashFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";

const RolesTable = ({ roles }) => {
  const [isEditingRole, setIsEditingRole] = useState(false);
  const [selectedRole, setSelectedRole] = useState(-1);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const deleteRole = useCallback((id) => {
    db.collection("roles").doc(id).delete();
    setShowConfirmBox(false);
  }, []);

  return (
    <>
      {isEditingRole && (
        <EditRoles
          show={isEditingRole}
          role={roles[selectedRole]}
          setIsEditingRole={setIsEditingRole}
        />
      )}
      <ConfirmBox
        show={showConfirmBox}
        setShowConfirmBox={setShowConfirmBox}
        deleteFunction={deleteRole}
        id={roles[selectedRole]?.id}
        text="Are you sure you want to delete this?"
      />
      <Table responsive striped bordered>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Name</th>
            {(currentUser.role === "admin" ||
              currentUser.role === "super admin") && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {roles &&
            roles?.map((role, index) => (
              <tr key={role.id}>
                <td>{index + 1}.</td>
                <td>{role.name}</td>
                {(currentUser.role === "admin" ||
                  currentUser.role === "super admin") && (
                  <td>
                    <div className="actions__btn__container">
                      <button
                        onClick={() => {
                          setIsEditingRole(true);
                          setSelectedRole(index);
                        }}
                        className="action__btn"
                      >
                        <MdModeEdit />
                      </button>
                      &nbsp;&nbsp;
                      <button
                        onClick={() => {
                          setSelectedRole(index);
                          setShowConfirmBox(true);
                        }}
                        className="action__btn"
                      >
                        <BsTrashFill />
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default memo(RolesTable);
