/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { PieChart, Pie, Legend, Cell, Tooltip } from "recharts";
import { Card } from "react-bootstrap";

const colors = [
  "#82ca9d",
  "#008000",
  "#006400",
  "#90EE90",
  "#32CD32",
  "#66FF00",
  "#98FB98",
];

export default function CustomerCitiesgraph({ data }) {
  const [graphData, setGraphData] = useState(undefined);

  const filterData = () => {
    let dataObj = [];
    const cityData = {};

    data.forEach((d) => {
      const city = d.address.city;
      if (city) {
        if (!cityData[city]) {
          cityData[city] = 1;
        } else {
          cityData[city] += 1;
        }
      }
    });
    let sorted = [];
    for (var i in cityData) {
      sorted.push([i, cityData[i]]);
    }
    sorted
      .sort(function (a, b) {
        return a[1] - b[1];
      })
      .reverse();
    sorted = sorted.slice(0, 7);
    sorted.forEach((d, i) => {
      dataObj.push({
        name: d[0],
        value: d[1],
      });
    });
    setGraphData(dataObj);
  };

  useEffect(() => {
    filterData();
  }, []);

  return (
    <Card className="p-2">
      <div className="chart-title">
        <h2>Total customers from top 7 cities</h2>
      </div>
      {graphData && (
        <PieChart width={600} height={300}>
          <Pie
            data={graphData}
            color="#000000"
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={120}
            fill="#82ca9d"
          >
            {graphData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend />
        </PieChart>
      )}
    </Card>
  );
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#fff",
          padding: "5px",
          border: "1px solid #ccc",
        }}
      >
        <label>
          {payload[0].name}: {payload[0].value} Customers
        </label>
      </div>
    );
  } else return null;
};
