import { useState, useContext, memo, useCallback } from "react";
import { db } from "../../utils/firebase";
import { AuthContext } from "../../context/authContext";
import Table from "react-bootstrap/Table";
import EditProductGroups from "./EditProductGroups";
import ConfirmBox from "../ConfirmBox/ConfirmBox";
import { BsTrashFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";

const ProductGroupsTable = ({ productGroups, customersInPg, ordersInPg }) => {
  const [isEditingProductGroups, setIsEditingProductGroups] = useState(false);
  const [selectedProductGroups, setSelectedProductGroups] = useState(-1);
  const [showConfirmBox, setShowConfirmBox] = useState(false);
  const { currentUser } = useContext(AuthContext);

  const deleteProductGroup = useCallback((id) => {
    db.collection("productgroups").doc(id).delete();
    setShowConfirmBox(false);
  }, []);

  return (
    <>
      {isEditingProductGroups && (
        <EditProductGroups
          show={isEditingProductGroups}
          productGroup={productGroups[selectedProductGroups]}
          setIsEditingProductGroups={setIsEditingProductGroups}
        />
      )}
      <ConfirmBox
        show={showConfirmBox}
        setShowConfirmBox={setShowConfirmBox}
        deleteFunction={deleteProductGroup}
        id={productGroups[selectedProductGroups]?.id}
        text="Are you sure you want to delete this?"
      />
      <Table responsive striped bordered>
        <thead>
          <tr>
            <th>Sr No.</th>
            <th>Name</th>
            <th>Total Customers</th>
            <th>Total Orders</th>
            {(currentUser.role === "admin" ||
              currentUser.role === "super admin") && <th>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {productGroups &&
            productGroups?.map((pg, index) => (
              <tr key={pg.id}>
                <td>{index + 1}.</td>
                <td>{pg.name}</td>
                <td>{customersInPg[pg.id] || "0"}</td>
                <td>{ordersInPg[pg.id] || "0"}</td>
                {(currentUser.role === "admin" ||
                  currentUser.role === "super admin") && (
                  <td>
                    <div className="actions__btn__container">
                      <button
                        onClick={() => {
                          setIsEditingProductGroups(true);
                          setSelectedProductGroups(index);
                        }}
                        className="action__btn"
                      >
                        <MdModeEdit />
                      </button>
                      &nbsp;&nbsp;
                      <button
                        onClick={() => {
                          setSelectedProductGroups(index);
                          setShowConfirmBox(true);
                        }}
                        className="action__btn"
                      >
                        <BsTrashFill />
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default memo(ProductGroupsTable);
