import { memo, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { db } from "../../utils/firebase";
import { v4 as uuidv4 } from "uuid";
import { BsTrashFill } from "react-icons/bs";
import { BiErrorCircle } from "react-icons/bi";
import { Form, Button } from "react-bootstrap";

const ContactPersons = ({ customerType, details, setDetails }) => {
  const [roles, setRoles] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const { uniquePhoneNumbers } = useContext(AuthContext);

  const isContactUnique = (number) => {
    if (uniquePhoneNumbers.includes(number)) return false;
    else return true;
  };

  const handleChange = (i, e) => {
    let newFormValues = [...details];
    newFormValues[i][e.target.name] = e.target.value;
    setDetails(newFormValues);
  };

  const addFormFields = () => {
    let n = details.length - 1;
    setErrorMsg("");
    if (!details[n].name || !details[n].phoneNumber1) {
      setErrorMsg("Name and First Contact Number is required.");
      return;
    } else if (!isContactUnique(details[n].phoneNumber1)) {
      setErrorMsg("Please fill Name and Phone Number of Customer");
      return;
    } else {
      if (customerType === "Individual") {
        setDetails([
          ...details,
          {
            id: uuidv4(),
            name: "",
            email: "",
            phoneNumber1: "",
            phoneNumber2: "",
            role: "secondary",
            dob: "",
          },
        ]);
      } else {
        setDetails([
          ...details,
          {
            id: uuidv4(),
            name: "",
            email: "",
            phoneNumber1: "",
            phoneNumber2: "",
            role: "",
            dob: "",
          },
        ]);
      }
    }
  };

  const removeFormFields = (i) => {
    let newFormValues = [...details];
    newFormValues.splice(i, 1);
    setDetails(newFormValues);
  };

  useEffect(() => {
    let abortController = new AbortController();
    db.collection("roles").onSnapshot((snapshot) => {
      setRoles(snapshot.docs.map((doc) => doc.data()));
    });
    return () => abortController.abort();
  }, []);

  return (
    <div>
      {customerType === "Company" && (
        <Form.Label style={{ fontWeight: 600 }}>Employee Details</Form.Label>
      )}
      {details?.map((person, index) => (
        <Form.Group className="d-flex mb-2" key={index}>
          <Form.Group>
            {index === 0 && (
              <Form.Label style={{ fontWeight: 500 }}>Name</Form.Label>
            )}
            <Form.Control
              type="text"
              placeholder="Name"
              name="name"
              value={person.name || ""}
              onChange={(e) => handleChange(index, e)}
              autoComplete="off"
            />
          </Form.Group>
          {customerType === "Company" && (
            <>
              &nbsp;&nbsp;&nbsp;
              <Form.Group style={{ fontWeight: 500 }}>
                {index === 0 && <Form.Label>Role</Form.Label>}
                <Form.Select
                  className="role__select"
                  name="role"
                  value={person.role || ""}
                  onChange={(e) => handleChange(index, e)}
                  autoComplete="off"
                >
                  {roles.map((role) => (
                    <option key={role.id}>{role.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </>
          )}
          &nbsp;&nbsp;&nbsp;
          <Form.Group>
            {index === 0 && (
              <Form.Label style={{ fontWeight: 500 }}>Email</Form.Label>
            )}
            <Form.Control
              type="text"
              placeholder="Email"
              name="email"
              value={person.email || ""}
              onChange={(e) => handleChange(index, e)}
              autoComplete="off"
            />
          </Form.Group>
          &nbsp;&nbsp;&nbsp;
          <Form.Group>
            {index === 0 && (
              <Form.Label style={{ fontWeight: 500 }}>
                Contact Number 1
              </Form.Label>
            )}
            <Form.Control
              type="text"
              placeholder="Contact Number 1"
              name="phoneNumber1"
              value={person.phoneNumber1 || ""}
              onChange={(e) => handleChange(index, e)}
              autoComplete="off"
            />
          </Form.Group>
          &nbsp;&nbsp;&nbsp;
          <Form.Group>
            {index === 0 && (
              <Form.Label style={{ fontWeight: 500 }}>
                Contact Number 2
              </Form.Label>
            )}
            <Form.Control
              type="text"
              placeholder="Contact Number 2"
              name="phoneNumber2"
              value={person.phoneNumber2 || ""}
              onChange={(e) => handleChange(index, e)}
              autoComplete="off"
            />
          </Form.Group>
          &nbsp;&nbsp;&nbsp;
          <Form.Group>
            {index === 0 && (
              <Form.Label style={{ fontWeight: 500 }}>Date of Birth</Form.Label>
            )}
            <Form.Control
              type="date"
              name="dob"
              value={person.dob || ""}
              onChange={(e) => handleChange(index, e)}
              placeholder="Date of Birth"
            />
          </Form.Group>
          &nbsp;&nbsp;&nbsp;
          <Form.Group>
            {index === 0 && (
              <Form.Label
                style={{ fontWeight: 500, paddingBottom: 47 }}
              ></Form.Label>
            )}
            <Button
              type="button"
              variant="danger"
              onClick={() => removeFormFields(index)}
              disabled={details.length === 1}
            >
              <BsTrashFill />
            </Button>
          </Form.Group>
        </Form.Group>
      ))}

      {/* Error Message */}
      {errorMsg && (
        <p className="error__msg" style={{ marginBottom: 20 }}>
          <BiErrorCircle />
          &nbsp;{errorMsg}
        </p>
      )}

      {/* Add Button */}
      <div className="d-flex mb-3 mt-2 justify-content-end">
        <Button type="button" variant="success" onClick={addFormFields}>
          Add
        </Button>
      </div>
    </div>
  );
};

export default memo(ContactPersons);
