import { memo } from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmBox = ({
  id,
  text,
  show,
  setShowConfirmBox,
  deleteFunction,
  logoutFunction,
}) => {
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShowConfirmBox(false)}
        animation={false}
        centered
      >
        <Modal.Body>{text}</Modal.Body>
        <Modal.Footer style={{ borderTop: "none" }}>
          <Button
            variant="light"
            className="bg-transparent"
            onClick={() => setShowConfirmBox(false)}
          >
            No
          </Button>
          <Button
            variant="light"
            className="bg-transparent"
            onClick={() => {
              if (logoutFunction) logoutFunction();
              else deleteFunction(id);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default memo(ConfirmBox);
