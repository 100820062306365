/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
} from "recharts";
import {
  getDateNDaysAgo,
  getDateNDaysAhead,
} from "../../utils/helperFunctions";
import { daysArr } from "../../utils/days";

export default function ScheduleCallsGraph({ data }) {
  const [graphData, setGraphData] = useState(undefined);
  const [total, setTotal] = useState(0);

  const filterData = () => {
    let dataObj = [];
    const temp = data?.filter((d) => {
      return (
        new Date(d.callback_date.seconds * 1000) >= getDateNDaysAgo(1) &&
        new Date(d.callback_date.seconds * 1000) <= getDateNDaysAhead(7)
      );
    });
    setTotal(temp.length);

    let tempDays = {};
    temp.forEach((d) => {
      const day = daysArr[new Date(d.callback_date.seconds * 1000).getDay()];
      if (!tempDays[day]) {
        tempDays[day] = 1;
      } else {
        tempDays[day] += 1;
      }
    });
    for (let i = 0; i < 7; i++) {
      const dayIndex = getDateNDaysAhead(i).getDay();
      dataObj.push({
        name: daysArr[dayIndex],
        Calls: tempDays[daysArr[dayIndex]] || 0,
      });
    }

    setGraphData(dataObj);
  };

  useEffect(() => {
    filterData();
  }, []);

  return (
    <Card className="p-2">
      <div className="chart-title">
        <h2>Scheduled Calls this week ({total})</h2>
      </div>
      {graphData && (
        <BarChart
          width={600}
          height={300}
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Calls" fill="#82ca9d" />
        </BarChart>
      )}
    </Card>
  );
}
